<template>
  <div class="content">
    <el-date-picker
      v-model="value1"
      type="week"
      :clearable="false"
      format="yyyy第WW周"
      :picker-options="SpickerOptions"
      placeholder="选择周"
      style="width: 136px"
      @change="getValue"
    />
    至
    <!-- format="yyyy 第 WW 周"  -->
    <el-date-picker
      v-model="value2"
      type="week"
      :clearable="false"
      format="yyyy第WW周"
      placeholder="选择周"
      :picker-options="pickerOptions"
      style="width: 136px"
      @change="getValue"
    />
  </div>
</template>

<script>
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
export default {
  props: {
    week: {
      type: Array,
      required: true
    }
  },

  data() {
    return {
      value1: null,
      value2: null,
      startWeek: null,
      endWeek: null,
      SpickerOptions: {
        firstDayOfWeek: 1
      }
    }
  },
  computed: {
    pickerOptions() {
      const that = this
      return {
        firstDayOfWeek: 1,
        disabledDate(time) {
          const startDate = that.value1
          const diffTime = dayjs(time.getTime()).startOf('week')
          const nowTime = dayjs(startDate).diff(diffTime, 'week')
          // 要求可选择当前到未来半年
          return nowTime < -25 || nowTime >= 1
        }
      }
    }
  },
  watch: {
    value1() {
      if (!this.value1) return
    },
    value2() {
      if (!this.value2) return
    }

  },
  created() {
  },
  mounted() {
    dayjs.extend(weekOfYear)
    dayjs.extend(isoWeek)
    this.value1 = this.week[0]
    this.value2 = this.week[1]
  },
  methods: {
    getValue() {
      const formatStartWeek = String(dayjs(this.value1).week()).padStart(2, '0')
      const formatEndWeek = String(dayjs(this.value2).week()).padStart(2, '0')
      this.startWeek = `${dayjs(this.value1).isoWeekYear()}${formatStartWeek}`
      this.endWeek = `${dayjs(this.value2).isoWeekYear()}${formatEndWeek}`
      this.$emit('startWeek', this.startWeek)
      this.$emit('endWeek', this.endWeek)
      if (this.value1 > this.value2) { // 保证value2大于value1
        this.value2 = ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
.content {
  display: flex;
  /deep/ .el-input__prefix {
    display: none;
  }
  /deep/ .el-input__inner {
    padding: 0;
    padding-left: 2px;
  }
}

</style>
