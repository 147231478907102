<template>
  <div>
    <div ref="formDiv">
      <el-form ref="form" :model="form" label-width="140px" style="display: flex;" :rules="FormRules">
        <div class="el-lt" style="width:85%;">
          <el-row :span="24" class="row-input" :class="{ 'show-row': showRow }">
            <el-col :span="8">
              <el-form-item label="销售预测起止时间" :class="$i18n.locale" prop="salesForecasttime">
                <el-date-picker
                  v-if="dimensions === 1"
                  v-model="form.salesForecasttime"
                  value-format="yyyyMM"
                  type="monthrange"
                  :picker-options="pickerOptionsMon"
                  :clearable="false"
                  range-separator="~"
                  start-placeholder="开始月份"
                  end-placeholder="结束月份"
                  style="width:100%;"
                />
                <WeekPicker v-if="isWeek == true" :week="defaultWeek" @endWeek="getEndWeek" @startWeek="getStartWeek" />
                <el-date-picker
                  v-if="dimensions === 3"
                  v-model="form.salesForecasttime"
                  value-format="yyyyMMdd"
                  type="daterange"
                  :picker-options="pickerOptionsDate"
                  :clearable="false"
                  range-separator="~"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width:100%;"
                />

              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="版本编号" :class="$i18n.locale" prop="versionCode">
                <el-select v-model="form.versionCode" filterable :placeholder="$t('page.selectPlaceholder')">
                  <el-option
                    v-for="item in dsbVertionOptions"
                    :key="item.versionCode"
                    :label="item.versionCode"
                    :value="item.versionCode"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="品牌">
                <el-select v-model="form.brandIdList" clearable filterable multiple collapse-tags :placeholder="$t('page.selectPlaceholder')">
                  <el-option
                    v-for="item in brandOptions"
                    :key="item.id"
                    :label="item.brandName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item :class="$i18n.locale" label="Style">
                <el-select v-model="form.style" value-key="id" clearable filterable :placeholder="$t('page.selectPlaceholder')" @change="styleSelectChange">
                  <el-option
                    v-for="item in styleOptions"
                    :key="item.id"
                    :label="item.styleName"
                    :value="item.id"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Color" :class="$i18n.locale">
                <el-select v-model="form.color" clearable filterable>
                  <el-option
                    v-for="item in colorOptions"
                    :key="item.id"
                    :label="item.colorName"
                    :value="item.colorName"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Size" :class="$i18n.locale">
                <el-select v-model="form.size" clearable filterable>
                  <el-option
                    v-for="item in sizeOptions"
                    :key="item.id"
                    :label="item.size"
                    :value="item.size"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Sku">
                <el-input v-model="form.sku" :placeholder="$t('page.inputPlaceholder')" />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="平台/站点/店铺" :class="$i18n.locale">
                <el-cascader
                  v-model="form.platformSiteShop"
                  clearable
                  collapse-tags
                  filterable
                  :options="alldatas"
                  :props=" { value: 'dropDownId', label: 'dropDownName', children: 'dropDownList', multiple:true}"
                  @change="platformSiteShopChange"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="类别" :class="$i18n.locale">
                <el-cascader
                  v-model="form.category"
                  clearable
                  collapse-tags
                  filterable
                  separator=">"
                  :filter-method="dataFilter"
                  :options="categoryOptions"
                  :props=" { value: 'id', label: 'categoryName', children: 'sonCategory', multiple:true}"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="上市年份" :class="$i18n.locale">
                <el-date-picker
                  v-model="form.listingYearList"
                  value-format="yyyy"
                  type="years"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="销售季节" :class="$i18n.locale">
                <el-select v-model="form.seasonIdList" clearable filterable multiple collapse-tags>
                  <el-option
                    v-for="item in seasonOptions"
                    :key="item.dictValue"
                    :label="item.remark"
                    :value="item.dictValue"
                  />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="售卖状态" :class="$i18n.locale">
                <el-select v-model="form.saleStateList" clearable filterable multiple collapse-tags>
                  <el-option label="在售" value="1" />
                  <el-option label="停售" value="2" />
                </el-select>
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="Operator" :class="$i18n.locale">
                <Select
                  v-model="form.operatorList"
                  api-key="listAllEmployee"
                  clearable
                  filterable
                  multiple
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="预测更新时间">
                <el-date-picker
                  v-model="form.createTime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width:100%;"
                />
              </el-form-item>
            </el-col>
            <el-col :span="8">
              <el-form-item label="数据同步时间">
                <el-date-picker
                  v-model="form.modifyTime"
                  type="daterange"
                  value-format="yyyy-MM-dd"
                  range-separator="-"
                  start-placeholder="开始日期"
                  end-placeholder="结束日期"
                  style="width:100%;"
                />
              </el-form-item>
            </el-col>
          </el-row>

        </div>
        <el-row ref="btnBox" class="el-rt" style="width:25%;">
          <el-col class="row-center">
            <el-button type="primary" :loading="Loading" @click="queryClickSearch()">{{ $t('page.search') }}</el-button>
            <el-button @click="resetClick()">{{ $t('page.reset') }}</el-button>
            <el-button type="text" @click="showRowClick()">{{ showRow ? $t('page.hide') : $t('page.expand') }}</el-button>
          </el-col>
        </el-row>
      </el-form>
    </div>

    <el-row :span="24" class="mb-3">
      <el-col :span="12">
        <el-button v-permission="'batchModify'" class="mr-3" type="primary" @click="handleBatchAdjust">批量调整</el-button>
        <el-button v-permission="'dataRecord'" class="mr-3" type="primary" @click="toData">数据同步</el-button>
        <el-button v-permission="'exportA'" :loading="exportLoading" class="mr-3" type="primary" @click="exportDetail">导出</el-button>
      </el-col>
      <el-col :span="8" style="text-align: right;">
        数据查看维度：
        <el-radio-group v-model="dimensions" @change="handleChangeRadio">
          <el-radio :label="1" @change="getDimensions">月</el-radio>
          <el-radio :label="2" @change="getDimensions">周</el-radio>
          <el-radio :label="3" @change="getDimensions">天</el-radio>
        </el-radio-group>
      </el-col>
      <el-col :span="4" style="text-align: right;">
        <el-checkbox v-model="form.ignoreHis">排除历史预测数据</el-checkbox>
      </el-col>
    </el-row>
    <vxe-table
      ref="multipleTable"
      :loading="tableLoading"
      class="mb-3 mt-3"
      :data="tableDatas"
      show-footer
      :footer-method="footerMethod"
      :max-height="tableMaxHeight"
    >
      <vxe-table-column
        title="序号"
        type="seq"
        width="80"
        align="center"
      />
      <vxe-table-column
        field="versionCode"
        title="版本编号"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="brandName"
        title="品牌"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="categoryName"
        title="类别"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="styleName"
        title="Style"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="color"
        title="Color"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="size"
        title="Size"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="skuName"
        title="Sku"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        align="left"
        field="platformName"
        title="平台"
        min-width="100"
      />
      <vxe-table-column
        field="siteCode"
        title="站点"
        align="center"
        min-width="80"
      />
      <vxe-table-column
        field="shopCode"
        title="店铺"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="listingYear"
        title="上市年份"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="seasonName"
        title="销售季节"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="positionName"
        title="产品定位"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="saleState"
        title="售卖状态"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="operator"
        title="Operator"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="createTime"
        title="预测更新时间"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="modifyByName"
        title="操作人"
        align="center"
        min-width="100"
      />
      <vxe-table-column
        field="modifyTime"
        title="数据同步时间"
        align="center"
        min-width="100"
      />
      <template v-if="!isWeek">
        <vxe-table-column
          v-for="(item, index) in salesForecastNumberList"
          :key="index"
          width="120"
          :field="item"
          :title="item"
          align="center"
        />
      </template>
      <template v-if="isWeek">
        <vxe-table-column
          v-for="value in Object.keys(WeekForecastNumberList)"
          :key="value"
          width="120"
          :title="value"
          align="center"
        >
          <vxe-table-column
            v-for="value1 in Object.keys(WeekForecastNumberList[value])"
            :key="value1"
            width="120"
            :field="value1"
            :title="value1"
            align="center"
          />
        </vxe-table-column>
      </template>
    </vxe-table>
    <div ref="paginationBox">
      <Paging :pager="pager" end @pagination="pagerUpdate" />
    </div>

    <!-- 批量导入 -->
    <BatchImport v-model="importDialogVisible" @refresh="queryClick" />
    <DataSynchronization ref="DataSynchronization" v-model="dataRecordDialogVisible" @refresh="queryClick" />
  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import { handleMonitorParams } from '@/utils'
import BatchImport from './components/BatchImport'
import DataSynchronization from './components/DataSynchronization'
// import WeekPicker from './components/WeekDatePicker'
import WeekPicker from '@/components/WeekDatePicker'
import { getBaseCategory, queryStyleList, querySizeList, queryColorList,
  usersList, dsbVersionList, LimitedqueryBrandList, getplatformSiteShop,
  getSalesForecastApi, planSkuSalesExport, querySeasonList } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'
import { getInfo } from '@/api/user'
import dayjs from 'dayjs'
import weekOfYear from 'dayjs/plugin/weekOfYear'
import isoWeek from 'dayjs/plugin/isoWeek'
import NP from 'number-precision'
import { omit } from 'lodash'
import Select from '@/components/Selection'
import { trackDimensionEvent, trackSiteSearch, handleBreadTitle, trackExportEvent, trackDialogEvent, trackPaginationEvent } from '@/utils/monitor'

export default {
  components: { Paging, BatchImport, DataSynchronization, WeekPicker, Select },

  data() {
    return {
      tableMaxHeight: 0,
      isWeek: false,
      showRow: true,
      dimensions: 1,
      startWeek: null,
      endWeek: null,
      minMon: dayjs().format('YYYYMM'),
      minDate: dayjs().format('YYYYMMDD'),
      defaultWeek: [dayjs().startOf('week').add(1, 'day'), dayjs().add(26, 'week').startOf('week').add(1, 'day')],
      form: {
        salesForecasttime: [dayjs().format('YYYYMM'), dayjs().add(18, 'month').format('YYYYMM')],
        platformSiteShop: [[1, 1, 1]],
        versionCode: '',
        brandIdList: [],
        style: '',
        color: '',
        size: '',
        sku: '',
        category: [],
        listingYearList: [],
        seasonIdList: [],
        saleStateList: [],
        ignoreHis: true,
        createTime: [],
        modifyTime: [],
        operatorList: []
      },
      // 动态列
      salesForecastData: [],
      salesForecastNumberList: [],
      WeekForecastData: [],
      WeekForecastNumberList: [],
      pager: {
        current: 1,
        size: 10,
        pages: 1,
        total: 0
      },
      styleOptions: [],
      brandOptions: [],
      seasonOptions: [],
      colorOptions: [],
      sizeOptions: [],
      brandlimitedOptions: [],
      useroptions: [],
      dsbVertionOptions: [],
      alldatas: [],
      categoryOptions: [],
      tableDatas: [],
      Loading: false,
      tableLoading: false,
      importDialogVisible: false,
      dataRecordDialogVisible: false,
      FormRules: {
        salesForecasttime: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ],
        versionCode: [
          { required: true, message: this.$t('page.required'), trigger: 'change' }
        ]
      },
      exportLoading: false
    }
  },
  computed: {
    salesForecastParams() {
      const [fcstStart, fcstEnd] = this.isWeek ? [this.startWeek, this.endWeek] : this.form.salesForecasttime
      const { platformSiteShop, style, color, size, category, createTime, modifyTime } = this.form
      const [createStartTime, createEndTime] = createTime || []
      const [modifyStartTime, modifyEndTime] = modifyTime || []

      const styleIdList = style ? [style] : []
      const colorList = color ? [color] : []
      const sizeList = size ? [size] : []
      const arr = []
      if (Array.isArray(platformSiteShop)) {
        for (let i = 0; i < platformSiteShop.length; i++) {
          if (Array.isArray(platformSiteShop[i]) && platformSiteShop[i].length >= 3) {
            const platformId = platformSiteShop[i][0]
            const siteId = platformSiteShop[i][1]
            const shopId = platformSiteShop[i][2]
            arr.push({ platformId: platformId, siteId: siteId, shopId: shopId })
          }
        }
      }
      const arr1 = []
      if (Array.isArray(category)) {
        for (let i = 0; i < category.length; i++) {
          arr1.push(parseInt(category[i].slice(-1)))
        }
      }
      // 平台站点id
      return Object.assign({}, this.pager, omit(this.form, ['createTime', 'modifyTime']), { styleIdList, colorList, sizeList, fcstStart, fcstEnd },
        { platSiteShopList: arr,
          dimensions: this.dimensions,
          lastCategoryIdList: arr1,
          createStartTime, createEndTime, modifyStartTime, modifyEndTime })
    },
    pickerOptionsMon() {
      const that = this
      return {
        onPick: ({ minDate, maxDate }) => {
          that.minMon = minDate
        },
        disabledDate: (time) => { // 查询时间跨度为18个月
          const startDate = that.minMon
          const diffTime = dayjs(time.getTime()).format('YYYYMM')
          const nowTime = dayjs(startDate).diff(diffTime, 'month')
          return nowTime > 18 || nowTime < -18
        }
      }
    },
    pickerOptionsDate() {
      const that = this
      return {
        onPick: ({ minDate, maxDate }) => {
          that.minDate = minDate
        },
        disabledDate(time) {
          const nowTime = that.minDate
          const diffTime = dayjs(nowTime).diff(dayjs(time.getTime()).format('YYYYMMDD'), 'day')
          return diffTime > 90 || diffTime < -90
        }
      }
    }
  },
  created() {

  },
  beforeDestroy() {
    window.removeEventListener('resize', this.setTableMaxHeight) // 移除监听器
  },
  async mounted() {
    this.setTableMaxHeight() // 初始化设置最大高度
    window.addEventListener('resize', this.setTableMaxHeight) // 监听窗口大小变化
    this._queryBrandList()
    this._querySeasonList()
    this._queryStyleList()
    this._limitedqueryBrandList()
    this._listPlatformSiteShop()
    this._dsbVersionList()
    this._CategoryList()
    this._usersList()
    dayjs.extend(weekOfYear)
    dayjs.extend(isoWeek)
  },
  methods: {

    setTableMaxHeight() {
      const clientHeight = document.body.clientHeight || document.documentElement.clientHeight
      this.tableMaxHeight = clientHeight - 300// 设置最大高度，减去工具栏、页脚等占用的高度
    },
    dataFilter(node, val) {
      if (!!~node.text.indexOf(val) || !!~node.text.toUpperCase().indexOf(val.toUpperCase())) {
        return true
      }
    },
    handleChangeRadio() {
      trackDimensionEvent(handleBreadTitle(this.$route) + `切换至${this.dimensions}`)
    },
    handleBatchAdjust() {
      this.importDialogVisible = true
      trackDialogEvent(handleBreadTitle(this.$route) + '批量调整FCST')
    },
    queryClickSearch() {
      this.queryClick(1)
      trackSiteSearch(
        JSON.stringify(handleMonitorParams(this.salesForecastParams)),
        handleBreadTitle(this.$route),
        this.pager.size
      )
    },
    toData() {
      this.dataRecordDialogVisible = true
      this.$refs.DataSynchronization.getDataSyn()
    },
    pagerUpdate(val) {
      this.pager = val
      trackPaginationEvent(
        handleBreadTitle(this.$route) + `-条目数${val.size}-当前页${val.current}`,
      )
      this.queryClick()
    },
    async resetClick() {
      this.form = this.$options.data.call(this).form
      this.dimensions = 1
      this.isWeek = false
      this.form.versionCode = this.dsbVertionOptions[this.dsbVertionOptions.length - 1].versionCode
      this.queryClick(1)
    },
    showRowClick() {
      this.showRow = !this.showRow
    },
    async queryClick(flag) {
      try {
        this.tableLoading = true
        this.Loading = true
        // 保存当前用户的平台站点店铺
        const { datas } = await getInfo()
        this.userName = datas.username
        const selectedDatas = localStorage.getItem('sales-forecast-synchronization')
        if (selectedDatas) {
          const newUser = selectedDatas.split('+')[0]
          const OldplatformSiteShop = selectedDatas.split('+')[1]
          if (newUser === this.userName) {
            var arr = OldplatformSiteShop.split(',')
            var result = []
            for (var i = 0; i < arr.length; i += 3) {
              result.push(arr.slice(i, i + 3))
            }
            this.form.platformSiteShop = result
          }
        }
        flag && flag === 1 ? this.pager.current = 1 : ''
        const { code, datas: { records, pager }} = await getSalesForecastApi(this.salesForecastParams, this.pager)
        if (code === 0) {
          this.pager = pager
          if (records.length <= 0) {
            this.tableDatas = records
          } else {
            const salesForecastData = records.map(item => item.activeSales)
            this.salesForecastNumberList = Object.keys(salesForecastData[0])
            this.tableDatas = records.map(e => Object.assign(e, e.activeSales))
            if (this.isWeek) {
              this.WeekForecastData = records.map(item => item.activeWeekSales)
              this.WeekForecastNumberList = this.WeekForecastData[0]
              this.tableDatas = this.tableDatas.map(e => e.activeWeekSales && Object.assign(e, ...Object.values(e.activeWeekSales)))
            }
          }
        }
        this.tableLoading = false
        this.Loading = false
      } catch (err) {
        console.log(err)
      } finally {
        this.Loading = false
        this.tableLoading = false
      }
    },
    async exportDetail() {
      try {
        this.exportLoading = true
        const params = {}
        Object.assign(params, this.salesForecastParams)
        const { code, msg } = await planSkuSalesExport({ params: JSON.stringify(params) })
        if (code === 0) {
          this.$notify({
            message: msg,
            type: 'success'
          })
        } else {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
        this.exportLoading = false
        trackExportEvent(handleBreadTitle(this.$route))
      } finally {
        this.exportLoading = false
      }
    },
    footerMethod({ columns, data }) {
      return [
        columns.map((column, columnIndex) => {
          if (columnIndex === 0) {
            return '总计'
          }
          if (columnIndex > 18) {
            const values = data.map(item => Number(item[column.property]))
            if (!values.every(value => isNaN(value))) {
              return values.reduce((prev, curr) => {
                const value = Number(curr)
                if (!isNaN(value)) {
                  return NP.plus(prev, curr)
                } else {
                  return prev
                }
              }, 0)
            }
          }
          return null
        })
      ]
    },
    // 维度选择器与绑定值
    getDimensions(val) {
      if (val === 1) {
        this.isWeek = false
        this.form.salesForecasttime = [dayjs().format('YYYYMM'), dayjs().add(18, 'month').format('YYYYMM')]
      } else if (val === 3) {
        this.isWeek = false
        this.form.salesForecasttime = [dayjs(new Date()).format('YYYYMMDD'), dayjs(new Date(new Date().setDate(new Date().getDate() + 90))).format('YYYYMMDD')]
      } else {
        this.isWeek = true
        const formatStartWeek = String(dayjs(this.defaultWeek[0]).week()).padStart(2, '0')
        const formatEndWeek = String(dayjs(this.defaultWeek[1]).week()).padStart(2, '0')
        this.startWeek = `${dayjs(this.defaultWeek[0]).isoWeekYear()}${formatStartWeek}`
        this.endWeek = `${dayjs(this.defaultWeek[1]).isoWeekYear()}${formatEndWeek}`
      }
      this.queryClick(1)
    },
    getEndWeek(val) {
      this.endWeek = val
    },
    getStartWeek(val) {
      this.startWeek = val
    },
    platformSiteShopChange(value) {
      const selectedDatas = `${this.userName}+${value}`
      localStorage.setItem('sales-forecast-synchronization', selectedDatas.toString())
    },
    // 下拉信息获取
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _querySeasonList() {
      const { datas } = await querySeasonList()
      this.seasonOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    styleSelectChange(value) {
      const data = this.styleOptions.find(item => item.id === value) || []
      this.form.color = ''
      this.form.size = ''
      this._queryColorList(data.id)
      this._querySizeList(data.id)
    },
    async _queryColorList(id) {
      const { datas } = await queryColorList({ styleId: id })
      this.colorOptions = datas
    },
    async _querySizeList(id) {
      const { datas } = await querySizeList({ styleId: id })
      this.sizeOptions = datas
    },
    async _limitedqueryBrandList() {
      const { datas } = await LimitedqueryBrandList()
      this.brandlimitedOptions = datas
    },
    async _usersList() {
      const { datas } = await usersList()
      this.useroptions = datas
    },
    async _dsbVersionList() {
      try {
        this.tableLoading = true
        this.Loading = true
        const { datas } = await dsbVersionList()
        this.dsbVertionOptions = datas

        this.form.versionCode = datas[datas.length - 1].versionCode
        this.queryClick(1)
      } catch {
        this.tableLoading = false
        this.Loading = false
      }
    },
    // 类别查询
    async _CategoryList() {
      const { datas } = await getBaseCategory()
      this.categoryOptions = datas
      // this.dsbVertionOptions = datas
    },
    // 所有平台站点店铺下拉
    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.getDataTree(datas)
      this.alldatas = datas
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
    }
  }
}
</script>

<style scoped lang="scss">

</style>
