<template>
  <div>
    <el-dialog
      title="数据同步"
      :visible.sync="visible"
      width="80%"
      :close-on-click-modal="false"
    >

      <el-form ref="form" :model="form" label-width="140px" style="display: flex;">
        <el-form-item :class="$i18n.locale" label="品牌">
          <el-select v-model="form.brandId" clearable filterable multiple collapse-tags :placeholder="$t('page.selectPlaceholder')">
            <el-option
              v-for="item in brandOptions"
              :key="item.id"
              :label="item.brandName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item :class="$i18n.locale" label="Style">
          <el-select v-model="form.styleId" value-key="id" clearable filterable multiple collapse-tags :placeholder="$t('page.selectPlaceholder')">
            <el-option
              v-for="item in styleOptions"
              :key="item.id"
              :label="item.styleName"
              :value="item.id"
            />
          </el-select>
        </el-form-item>
        <el-form-item label="平台/站点/店铺" :class="$i18n.locale">
          <el-cascader
            v-model="form.platformSiteShop"
            clearable
            collapse-tags
            filterable
            :options="alldatas"
            :props=" { value: 'value', label: 'dropDownName', children: 'dropDownList', multiple:true}"
          />
        </el-form-item>
      </el-form>
      <el-col :span="24" style="text-align: right;">
        <el-button class="mr-3" type="primary" @click="getNewDatas">获取最新数据</el-button>
      </el-col>

      <div><span>数据同步记录</span></div>
      <vxe-table
        ref="multipleTable"
        max-height="500px"
        align="center"
        :data="dataList"
        :row-config="{ height: 80 }"
      >
        <vxe-table-column width="90" type="seq" title="序号" />
        <vxe-table-column field="createByName" title="操作人" />
        <vxe-table-column field="createTime" title="操作时间" width="100px" />
        <vxe-table-column field="modifyTime" title="完成时间" width="100px" />
        <vxe-table-column field="type" title="类型" width="100px" />
        <vxe-table-column field="statusI18" title="状态" width="100px" />
        <vxe-table-column field="getNew" title="同步内容" min-width="150px">
          <!-- {{ row.brand&&row.stock&&row.style ? `品牌:${row.brand} style:${row.style} 平台/站点/店铺:${row.stock}` : '全量导入' }} -->
          <template #default="{row}">
            {{ getRowDescription(row) }}
          </template>
        </vxe-table-column>
      </vxe-table>
      <Paging :page-sizes="[10, 20, 40, 100]" :pager="pager" end @pagination="pagerUpdate" />

      <span slot="footer" class="dialog-footer">
        <el-button type="primary" @click="$emit('input',false)">取 消</el-button>
      </span>
    </el-dialog>

  </div>
</template>

<script>
import Paging from '@/components/Pagination'
import commodityInfoDict from '@/mixin/commodityInfoDict.js'
import { queryStyleList, getplatformSiteShop, synByHand, listRecord } from '@/api/scm-api'
import { queryBrandList } from '@/api/listSelection'

export default {
  components: { Paging },
  mixins: [commodityInfoDict],

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      pager: { size: 20, current: 1, total: 0 },
      form: {
        brandId: [],
        styleId: [],
        platformSiteShop: []
      },
      brandOptions: [],
      styleOptions: [],
      alldatas: [],
      dataList: [],
      getNewLoading: false
    }
  },

  computed: {
    visible: {
      get() {
        return this.value
      },
      set(val) {
        this.$emit('input', val)
      }
    },
    DatasParams() {
      // 传值name
      const { platformSiteShop, styleId, brandId } = this.form
      const styleName = this.styleOptions.filter(item => styleId.includes(item.id)).map(item => item.styleName)
      const brandName = this.brandOptions.filter(item => brandId.includes(item.id)).map(item => item.brandName)
      const platformSiteShopVOList = []
      const platformId = []
      const platformName = []
      const siteId = []
      const siteCode = []
      const shopId = []
      const shopCode = []
      if (platformSiteShop) {
        if (Array.isArray(platformSiteShop)) {
          for (let i = 0; i < platformSiteShop.length; i++) {
            if (Array.isArray(platformSiteShop[i]) && platformSiteShop[i].length >= 3) {
              const temp1 = platformSiteShop[i][0].split('&&')
              platformId.push(parseInt(temp1[0]))
              platformName.push(temp1[1])
              const temp2 = platformSiteShop[i][1].split('&&')
              siteId.push(parseInt(temp2[0]))
              siteCode.push(temp2[1])
              const temp3 = platformSiteShop[i][2].split('&&')
              shopId.push(parseInt(temp3[0]))
              shopCode.push(temp3[1])
              platformSiteShopVOList.push({ platformId: platformId[i], platformName: platformName[i], siteId: siteId[i], siteCode: siteCode[i], shopId: shopId[i], shopCode: shopCode[i] })
            }
          }
        }
      }
      return Object.assign({}, this.form, { platSiteShopList: platformSiteShopVOList }, { styleName, brandName })
    }
  },
  watch: {
    visible(val) {
      if (val) {
        this.$refs.form?.clearValidate()
        this.$refs.form?.resetFields()
      }
    }
  },
  created() {
  },
  mounted() {
    this._queryStyleList()
    this._queryBrandList()
    this._listPlatformSiteShop()
    this.getDataSyn()
  },
  methods: {
    getRowDescription(row) {
      const { brand, stock, style } = row
      if (brand && stock) {
        return `品牌:${brand} style:${style} 平台/站点/店铺:${stock}`
      } else if (brand && stock && !style) {
        return `品牌:${brand} 平台/站点/店铺:${stock}`
      } else if (brand && !stock && !style) {
        return `品牌:${brand}`
      } else if (!brand && stock && !style) {
        return `style:${style}`
      } else if (!brand && !stock && style) {
        return `style:${style}`
      } else if (!brand && stock && style) {
        return `style:${style} 平台/站点/店铺:${stock}`
      } else if (!brand && stock && !style) {
        return `平台/站点/店铺:${stock}`
      } else {
        return '全量导入'
      }
    },
    pagerUpdate(val) {
      this.pager = val
      this.getDataSyn()
    },
    // 预览
    async getDataSyn() {
      const { code, datas: { records, pager }} = await listRecord(this.DatasParams, this.pager)
      if (code === 0) {
        this.dataList = records
        this.pager = pager
      }
    },
    // 手动同步
    async getNewDatas() {
      try {
        this.getNewLoading = true
        this.getDataSyn()
        const { code, msg } = await synByHand(this.DatasParams)
        if (code === 0) {
          this.$notify({
            title: msg,
            message: msg,
            type: 'success'
          })
        }
      } finally {
        this.getDataSyn()
        this.getNewLoading = false
      }
    },
    // 下拉信息
    async _queryBrandList() {
      const { datas } = await queryBrandList()
      this.brandOptions = datas
    },
    async _queryStyleList() {
      const { datas } = await queryStyleList()
      this.styleOptions = datas
    },
    async _listPlatformSiteShop() {
      const { datas } = await getplatformSiteShop()
      this.getDataTree(datas)
      this.alldatas = datas
    },
    getDataTree(data) {
      if (!Array.isArray(data)) return
      for (let i = 0; i < data.length; i++) {
        data[i].value = `${data[i].dropDownId}&&${data[i].dropDownName}`
        this.getDataTree(data[i].dropDownList)
      }
    }
  }
}
</script>

<style scoped lang="scss">

/deep/.el-select {
            width: 100%  !important;
        }
</style>
